          
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Container,Row,Col, Spinner } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Search } from 'react-bootstrap-icons';
import $ from 'jquery';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ImageGallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const[metatage,setMetaData]=useState()

  const loaction = useLocation()

 

const Imageclick = ()=>{
 
 if (loaction.pathname === '/gallery') {
    $(".navbar").css("z-index", "auto");


  }
  else{
    $(".navbar").css("z-index", "1020");

  }


}

  const imagepath = "https://admin.autorentmotorcycle.com/public/images/gallery/";

  useEffect(() => {
    axios.get('https://admin.autorentmotorcycle.com/api/getGallery')
      .then(res => {
        setImages(res.data.data.map(image => imagepath + image.image));
        setLoading(false)
      })
      .catch(error => {
      });
      axios.get('https://admin.autorentmotorcycle.com/api/getSeoMetaTagsBySlug/gallery')
      .then(res =>{setMetaData(res.data.data)})
  }, []);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
    if (loaction.pathname === '/gallery') {
      $(".navbar").css("z-index", "1020");
  
  
    }
   


  };

  return (
    <>
     {metatage &&
        <Helmet>
        <title>{metatage.seo.meta_title}</title>

        <meta name="description" content={metatage.seo.meta_description} />
        <meta name="keywords" content={metatage.seo.meta_keywords} />
        <link rel="canonical" href={metatage.seo.canonical} />
        <meta name="robots" content={metatage.seo.robots}/>
<meta property="og:title" content={metatage.seo.og_title} />
<meta property="og:description" content={metatage.seo.og_description} />
<meta property="og:image" content={metatage.seo.og_image} />
<meta property="og:url" content={metatage.seo.og_url} />
<meta property="og:type" content={metatage.seo.og_type} />
<meta property="og:locale" content={metatage.seo.og_locale} />
        </Helmet>
}
    {loading ?
     <Container>
     <Row style={{height:'650px',padding:'0px'}}>
       <Col style={{display:'flex',justifyContent:'center',alignContent:'center',marginTop:'10%'}}>
      {/* <Spinner style={{color:'red'}}/>  */}
      <img src='/Asest/Homepage/giphy.gif' width={'100px'} height={'100px'} />
      </Col>
      </Row>
      </Container>:
    <Container fluid className='mt-5'>
    <h4 className='text-center'>Gallery</h4>

                  <Row className='images-row mb-5'>

                  {
                        images && images.map((image, index) => {
                            return (
                                <Col lg={3} key={index} style={{padding:'10px'}}>
                                    <figure className="hover-img">
                                    <img src={image} width={'100%'}  />
                                    <figcaption> 
                                      
                                      <Search color="white" onClick={()=>{ openLightbox(index); Imageclick() }} />  
 </figcaption>
 </figure>
                                </Col>                           )
                        })
                    }
                    </Row>

    </Container>
}
   

      <div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        
        
        />
      )}
      </div>
     
    </>
  );
};

export default ImageGallery;
