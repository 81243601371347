import { Container ,Row,Col} from 'bootstrap-4-react/lib/components/layout'
import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Helmet } from 'react-helmet';


function Branddetails() {
  const[metadata,setMetaData]=useState()

  return (
    <>
    <Helmet>
    <title>Autorent Motorcycle - Unicorn</title>
     <meta name="description" content="Unicorn" />
     <meta name="keywords" content="Unicorn"></meta>
    </Helmet>
    <Container fluid  className="mt-5">
      <h4  className=" text-center">HONDA UNICORN</h4>

      <Row className="row-info-details mb-5" >
        <Col lg={5} >
          <div style={{marginTop:'15%'}}>
            <p>The Honda Unicorn is a motorcycle developed by Honda Motorcycle & Scooter India and introduced in 2004. It was internally called the CBF150M. The engine was taken from the CRF150F post 2005 engine. The previous version of CRF150F was the old</p>
          </div>
        </Col>
        <Col lg={5}>
          <div>
          <img src='/Brands/unicorn-bike++.png' width={'100%'} height={'auto'} />
          </div>
        </Col>
      </Row>
      <Row className="brands-inside-row">
        <Col lg={7} >

        <Table striped bordered hover>
      <thead>
        <tr>
          
        <th className='text-center' colSpan={2}> Engine & Transmission </th>
        </tr>
      </thead>
      <tbody>
       
        <tr >
        
          <td >Engine </td>
        
        
          <td>162.7 cc,Air Cooled,4 stroke,SI Engine</td>
        
          
        </tr>
        <tr>

          <td>Compression Ratio</td>
        <td>01.10</td>
        </tr>
        <tr>
          <td>Bore and Stroke</td>
          <td>57.3mm * 63mm</td>
        </tr>
        <tr>
          <td>Ignition</td>
          <td>Kick/Self</td>
        </tr>
      
      
      </tbody>
    </Table>
        </Col>

        <Col lg={7} className="mt-5">

        <Table striped bordered hover>
      <thead>
        <tr>
          
        <th className='text-center' colSpan={2}>Drive Train</th>
        </tr>
      </thead>
      <tbody>
       
        <tr >
        
          <td >Transmission</td>
        
        
          <td>Five-speed</td>
        
          
        </tr>
        <tr>

          <td>Final-Drive</td>
        <td>Chain</td>
        </tr>
       
      </tbody>
    </Table>
        </Col>
        <Col lg={7} className="mt-5">

        <Table striped bordered hover>
      <thead>
        <tr>
          
        <th className='text-center' colSpan={2}>Chassis/Suspension/Brakes</th>
        </tr>
      </thead>
      <tbody>
       
        <tr >
        
          <td >Front and Suspension</td>
        
        
          <td>Diamond,Telescopic Forck (Front),Monoshock (Rear)</td>
        
          
        </tr>
        <tr>

          <td>Front Brake</td>
        <td>240 mm Disk</td>
        </tr>
        <tr>
            <td>Rear Brake</td>
            <td> 130 mm Drum (CBS optional)</td>
        </tr>
        <tr>
            <td>Front Tyre</td>
            <td>80/100-17 - tubeless</td>
        </tr>
        <tr>
            <td>Rear Tyre</td>
            <td>110/80-17 - tubeless</td>
        </tr>
       
      
      
      </tbody>
    </Table>
         
        </Col>


        <Col lg={7} className="mt-5">

<Table striped bordered hover>
<thead>
<tr>
  
<th className='text-center' colSpan={2}>Dimensions</th>
</tr>
</thead>
<tbody>

<tr >

  <td >Wheelbase</td>


  <td>1324 mm</td>

  
</tr>
<tr>

  <td>Seat Height</td>
<td>798 mm</td>
</tr>
<tr>
    <td>Curb Weight</td>
    <td>135 kgs</td>
</tr>
<tr>
    <td>Fuel Capacity</td>
    <td>12 liters</td>
</tr>



</tbody>
</Table>
 
</Col>
{/* <Col lg={7} className="mt-5">

<Table striped bordered hover>
<thead>
<tr>
  
<th className='text-center' colSpan={2}>Others</th>
</tr>
</thead>
<tbody>

<tr >

  <td >Model ID</td>


  <td>CB 160</td>

  
</tr>
<tr>

  <td>Warranty </td>
<td>six months or 10000 kms</td>
</tr>




</tbody>
</Table> 
 
</Col>*/}


      </Row>
     

     
    </Container>


    </>
      
  
  )
}

export default Branddetails
