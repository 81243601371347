import { Container, Row, Col } from 'bootstrap-4-react/lib/components/layout';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { MdEmail } from "react-icons/md";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';

function BasicExample() {
  const [data, setData] = useState("data");
  const [metatage, setMetaData] = useState();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company_name: '',
    phone_number: '',
    model: '',
    query: '',
    message: ''
  });

  const [showToast, setShowToast] = useState({
    show: false,
    message: '',
    type: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmits = (event) => {
    event.preventDefault();

    axios.post('https://admin.autorentmotorcycle.com/api/postContactus', formData)
      .then(res => {
        setData(res.data);

        toast(res.data.message)

        setFormData({
          name: '',
          email: '',
          phone_number: '',
          company_name: '',
          message: '',
          model: '',
          query: '',
        });
      })
      .catch(err => {
        toast(err.response.data.data.name[0])
        toast(err.response.data.data.email[0])
        toast(err.response.data.data.phone_number[0])
        toast(err.response.data.data.phone_number[1])
        toast(err.response.data.data.company_name[0])
        toast(err.response.data.data.message[0])
        toast(err.response.data.data.query[0])
        toast(err.response.data.data.query[0])



      });
  };


  console.log(data)

  useEffect(() => {
    axios.get('https://admin.autorentmotorcycle.com/api/getSeoMetaTagsBySlug/contact')
      .then(res => { setMetaData(res.data.data) })
  }, []);


  return (
    <>
      {metatage &&
        <Helmet>
          <title>{metatage.seo.meta_title}</title>
          <meta name="description" content={metatage.seo.meta_description} />
          <meta name="keywords" content={metatage.seo.meta_keywords} />
          <link rel="canonical" href={metatage.seo.canonical} />
          <meta name="robots" content={metatage.seo.robots} />
          <meta property="og:title" content={metatage.seo.og_title} />
          <meta property="og:description" content={metatage.seo.og_description} />
          <meta property="og:image" content={metatage.seo.og_image} />
          <meta property="og:url" content={metatage.seo.og_url} />
          <meta property="og:type" content={metatage.seo.og_type} />
          <meta property="og:locale" content={metatage.seo.og_locale} />
        </Helmet>
      }
      <Container fluid >
        <ToastContainer />
        <Row>
        </Row>
        <Row className="contact-row mb-5" >
          <h4 className='mb-5 text-center mt-5'>Get In Touch</h4>
          <Col lg={6} md={6}>
            <Form onSubmit={handleSubmits}>
              <Row>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      onChange={handleInputChange}
                      name='name'
                      value={formData.name}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name='email'
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="company_name">
                    <Form.Control
                      type="text"
                      placeholder="Company Name"
                      onChange={handleInputChange}
                      name='company_name'
                      value={formData.company_name}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="phone_number">
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      name='phone_number'
                      value={formData.phone_number}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="model">
                    <Form.Control
                      type="text"
                      placeholder="Models"
                      name='model'
                      value={formData.model}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleInputChange}
                    name='query'
                    value={formData.query}
                  >
                    <option value="">Query</option>
                    <option value="1">General</option>
                    <option value="2">Lease</option>
                    <option value="3">Breakdown</option>
                  </select>
                </Col>
                <Col lg={12} md={12} className="mt-3">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Text Box"
                      style={{ height: '120px' }}
                      name='message'
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
          <Col lg={2} md={3} xs={12} className="contact-us">
            <div className='contact-us-2'>
              <Row className="card-position">
                <Col lg={12}>
                  <Card className='text-center card-position-card' style={{ padding: '45px' }}>
                    <span>
                      <BsFillTelephoneFill fontSize={'40px'} color='white' />
                    </span>
                    <h5 style={{ fontSize: '18px', color: 'white' }}>+971 600 549 993</h5>
                  </Card>
                </Col>
              </Row>
              <Row className="card-position">
                <Col lg={12}>
                  <Card className='text-center card-position-card  card-2' style={{ position: 'relative', top: '-5%' }}>
                    <span>
                      <MdEmail fontSize={'40px'} color='white' style={{ marginTop: '10%' }} />
                    </span>
                    <h5 style={{ fontSize: '18px', color: 'white', marginTop: '-30%' }}> info@motorcyclerental.com</h5>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          {/* <Col lg={12}  style={{position:"absolute",  display: 'flex',justifyContent: 'end'
}}>
          <ToastContainer  style={{position:"relative"}} className="p-3 mt-5">
          {showToast.type.email ?
            <Toast style={{ backgroundColor: "red"  }} >
              <Toast.Body>{showToast.type.email}</Toast.Body>
            </Toast>
            : null}

          {showToast.type.name ?
            <Toast style={{ backgroundColor: "red" }} onClose={() => setShowToast({ show: false, message: '', type: '' })} show={showToast.show} delay={3000} autohide bg={showToast.type === 'success' ? 'success' : 'red'}>
              <Toast.Body>{showToast.type.name}</Toast.Body>
            </Toast>
            : null}

          {showToast.type.query ?
            <Toast style={{ backgroundColor: "red" }} onClose={() => setShowToast({ show: false, message: '', type: '' })} show={showToast.show} delay={3000} autohide bg={showToast.type === 'success' ? 'success' : 'red'}>
              <Toast.Body>{showToast.type.query}</Toast.Body>
            </Toast>
            : null}
          {showToast.type.model ?
            <Toast style={{ backgroundColor: "red" }} onClose={() => setShowToast({ show: false, message: '', type: '' })} show={showToast.show} delay={3000} autohide bg={showToast.type === 'success' ? 'success' : 'red'}>
              <Toast.Body>{showToast.type.model}</Toast.Body>
            </Toast>
            : null}
          {showToast.type.company_name ?
            <Toast style={{ backgroundColor: "red" }} onClose={() => setShowToast({ show: false, message: '', type: '' })} show={showToast.show} delay={3000} autohide bg={showToast.type === 'success' ? 'success' : 'red'}>
              <Toast.Body>{showToast.type.company_name}</Toast.Body>
            </Toast>
            : null}
          {showToast.type.phone_number ?
            <Toast style={{ backgroundColor: "red" }} onClose={() => setShowToast({ show: false, message: '', type: '' })} show={showToast.show} delay={3000} autohide bg={showToast.type === 'success' ? 'success' : 'red'}>
              <Toast.Body>{showToast.type.phone_number}</Toast.Body>
            </Toast>
            : null}
          {showToast.type.message ?
            <Toast style={{ backgroundColor: "red" }} onClose={() => setShowToast({ show: false, message: '', type: '' })} show={showToast.show} delay={3000} autohide bg={showToast.type === 'success' ? 'success' : 'red'}>
              <Toast.Body>{showToast.type.message}</Toast.Body>
            </Toast>
            : null}

        </ToastContainer>
          </Col> */}


        </Row>
        <Row>
          <Col lg={12} className='mt-4 mb-5'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d115550.95537037955!2d55.2772325!3d25.1705801!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f673aa8c3fc5d%3A0x425e710d8c1e9f59!2sAutorent%20Motorcycle%20Rental%20-%20Ras%20Al%20Khor!5e0!3m2!1sen!2sin!4v1684903221549!5m2!1sen!2sin"
              width={'100%'}
              height={'300px'}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>


      </Container>
    </>
  );
}

export default BasicExample;
