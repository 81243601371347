import { Container ,Row,Col} from 'bootstrap-4-react/lib/components/layout'
import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Helmet } from 'react-helmet';


function Branddetails() {
  const[metadata,setMetaData]=useState()

  return (
    <>
    <Helmet>
    <title>Autorent Motorcycle - Hunk</title>
     <meta name="description" content="Hunk" />
     <meta name="keywords" content="Hunk"></meta>
    </Helmet>
    <Container fluid  className="mt-5">
      <h4  className="text-center">HERO HUNK</h4>
      <Row className="row-info-details" >
        <Col lg={5} >
          <div style={{marginTop:'15%'}}>
            <p>Powering the RTR 160 2V is a single-cylinder, 159.7cc, air-cooled engine which produces 16.04PS and 13.85Nm. It comes paired with a 5-speed gearbox</p>
          </div>
        </Col>
        <Col lg={5}>
          <div>
          <img src='/Brands/hunk++.png'  width={'100%'} height={'auto'}/>
          </div>
        </Col>
      </Row>
      <Row className="brands-inside-row">
        <Col lg={7} >

        <Table striped bordered hover>
      <thead>
        <tr>
          
        <th className='text-center' colSpan={2}> Engine & Transmission </th>
        </tr>
      </thead>
      <tbody>
       
        <tr >
        
          <td >Engine Type</td>
        
        
          <td>4 stroke</td>
        
          
        </tr>
        <tr>

          <td>Engine Displacement</td>
        <td>159.7 cc</td>
        </tr>
        <tr>
          <td>Max Power</td>
          <td>12.44 KW (16.28 bhp) @8000 rpm</td>
        </tr>
        <tr>
          <td>Max Torque</td>
          <td>14.8 Nm @ 6500 rpm</td>
        </tr>
        <tr>
          <td>Starting</td>
          <td>Electric Start</td>
        </tr>
        <tr>
          <td>Transmission</td>
          <td>5 Speed</td>
        </tr>
        <tr>
          <td>Fuel tank capacity</td>
          <td>12Litrs</td>
        </tr>
      
      </tbody>
    </Table>
        </Col>

        <Col lg={7} className="mt-5">

        <Table striped bordered hover>
      <thead>
        <tr>
          
        <th className='text-center' colSpan={2}>Dimensions</th>
        </tr>
      </thead>
      <tbody>
       
        <tr >
        
          <td >Length</td>
        
        
          <td>2050 mm</td>
        
          
        </tr>
        <tr>

          <td>Width</td>
        <td>790 mm</td>
        </tr>
        <tr>
          <td>HEIGHT</td>
          <td>1050 mm</td>
        </tr>
        <tr>
          <td>Wheel Base</td>
          <td>1357 mm</td>
        </tr>
        <tr>
          <td>Saddle Height</td>
          <td>800 mm</td>
        </tr>
        <tr>
          <td>Ground Clearence</td>
          <td>180 mm</td>
        </tr>
        <tr>
          <td>Weight</td>
          <td>143-145 kg</td>
        </tr>
        <tr>
          <td>Chassis</td>
          <td>Double Cradle Split Synchro STIFF</td>
        </tr>
      
      </tbody>
    </Table>
        </Col>
        <Col lg={7} className="mt-5">

        <Table striped bordered hover>
      <thead>
        <tr>
          
        <th className='text-center' colSpan={2}>Suspension</th>
        </tr>
      </thead>
      <tbody>
       
        <tr >
        
          <td >Front Suspension</td>
        
        
          <td>Telescopic Oil Damped</td>
        
          
        </tr>
        <tr>

          <td>Rear Suspension</td>
        <td>Mono Shock</td>
        </tr>
       
      
      
      </tbody>
    </Table>
         
        </Col>


        <Col lg={7} className="mt-5">

<Table striped bordered hover>
<thead>
<tr>
  
<th className='text-center' colSpan={2}>Brakes</th>
</tr>
</thead>
<tbody>

<tr >

  <td >Front & Rear</td>


  <td>270 mm Petal Disk</td>

  
</tr>
<tr>

  <td></td>
<td>130 mm Dia Drum / 200 mm petal Disk</td>
</tr>



</tbody>
</Table>
 
</Col>
<Col lg={7} className="mt-5">

<Table striped bordered hover>
<thead>
<tr>
  
<th className='text-center' colSpan={2}>Tyres</th>
</tr>
</thead>
<tbody>

<tr >

  <td >Front</td>


  <td>90/90 * 17"(Tubeless)</td>

  
</tr>
<tr>

  <td>Rear </td>
<td>130/70 * 17"(Tubeless) (Disk) /</td>
</tr>
<tr>
<td></td>
<td>110/80 * 17"(Tubeless) (Drum) </td>
</tr>



</tbody>
</Table>
 
</Col>
<Col lg={7} className="mt-5">

<Table striped bordered hover>
<thead>
<tr>
  
<th className='text-center' colSpan={2}>Control Electricals</th>
</tr>
</thead>
<tbody>

<tr >

  <td >Battery</td>


  <td>12V,6Ah MF</td>

  
</tr>
<tr>

  <td>Headlamp</td>
<td>AHO LED Headlamp With LED Position Lamp</td>
</tr>
<tr>
  <td>
    Tail lamp
  </td>
  <td>LED</td>
</tr>



</tbody>
</Table>
 
</Col>
      </Row>
     

      

    </Container>


    </>
      
  
  )
}

export default Branddetails
