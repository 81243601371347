import axios from 'axios';
import { Container } from 'bootstrap-4-react/lib/components/layout'
import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Spinner, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function Brands() {
  const [brands, setBrands] = useState()
  const [activeId, setActiveId] = useState(0)
  const [isLoading, setIsLoadings] = useState(true)
  const[metatage,setMetaData]=useState()


  useEffect(() => {
    axios.get('https://admin.autorentmotorcycle.com/api/getBrands')
      .then(response => { setBrands(response.data.data); setIsLoadings(false) })

      axios.get('https://admin.autorentmotorcycle.com/api/getSeoMetaTagsBySlug/brand')
      .then(res =>{setMetaData(res.data.data)})

  }, [0])


  return (
    <>

{metatage &&
        <Helmet>
        <title>{metatage.seo.meta_title}</title>

        <meta name="description" content={metatage.seo.meta_description} />
        <meta name="keywords" content={metatage.seo.meta_keywords} />
        <link rel="canonical" href={metatage.seo.canonical} />
        <meta name="robots" content={metatage.seo.robots}/>
<meta property="og:title" content={metatage.seo.og_title} />
<meta property="og:description" content={metatage.seo.og_description} />
<meta property="og:image" content={metatage.seo.og_image} />
<meta property="og:url" content={metatage.seo.og_url} />
<meta property="og:type" content={metatage.seo.og_type} />
<meta property="og:locale" content={metatage.seo.og_locale} />
        </Helmet>
}
      {isLoading ?
        <Container>
          <Row style={{ height: '650px', padding: '0px' }}>
            <Col style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '10%' }}>
              {/* <Spinner style={{color:'red'}}/>  */}
              <img src='/Asest/Homepage/giphy.gif' width={'100px'} height={'100px'} />
            </Col>
          </Row>
        </Container> :
        <Container fluid >
          <Row >
            <img src='/banner.jpg' style={{ padding: '0px' }} />

            <Tab.Container id="left-tabs-example" defaultActiveKey={activeId}>
              {brands &&
                brands.map((item, index) => {
                  return (

                    <Tab.Content key={index} style={{ position: 'absolute' }}>
                      <Tab.Pane eventKey={index}>

                        <Row style={{ marginTop: '6%' }}>
                          <Col lg={4}>
                            <div style={{ position: 'relative', marginTop: '0%', left: '40%' }} className='bike-explore'>
                              <span style={{ margin: '0px', fontWeight: '700', fontSize: '20px', padding: '10px' }}>{item.brand_title}</span>
                              <span style={{ margin: '0px', fontWeight: '700', fontSize: '20px' }}>{item.brand_model}</span>
                              <p > <Button style={{ background: '#C4181F', marginLeft: '2%', marginTop: '2%' }}><Link to={item.slug}>Explore</Link></Button></p>
                            </div>
                          </Col>
                          <Col lg={5} md={5} sm={8} xs={5} className='bike-brand-img' style={{ position: 'relative', right: '4%' }} >
                            <img
                              src={item.large_image_path + '/' + item.brand_image}
                              width={'100%'}
                              alt={item.brand_model} />
                          </Col>
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  );
                })}
              <Row style={{ display: 'flex', justifyContent: 'center' }} className='mb-4 mt-2'>
                <Col lg={10} sm={12} xs={12}>
                  <Nav className="flex-row " >
                    {brands &&
                      brands.map((item, index) => {
                        return (

                          <Nav.Item key={index}>

                            <Nav.Link onClick={() => setActiveId(index)} eventKey={index}>
                              <p className='owl-theme-p'>{item.brand_title}</p>
                              <img
                                src={item.large_image_path + '/' + item.brand_model_image}
                                alt={item.brand_title}
                                width={'100%'}
                                height={'auto'}
                              /></Nav.Link>

                          </Nav.Item>
                        );
                      })}
                  </Nav>
                </Col>
              </Row>
            </Tab.Container>
          </Row>



























        </Container>
      }
    </>
  )
}

export default Brands









