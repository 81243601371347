import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FiInstagram } from 'react-icons/fi'
import { FaFacebookF, FaLinkedinIn, FaHome, FaWhatsapp } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import Header from './Header';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { Button, Card, Modal } from 'bootstrap-4-react/lib/components';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { BsFillTelephoneForwardFill } from 'react-icons/bs'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import BookNow from './BookNow';
import LoadingSpinner from './spinner';



function ColorSchemesExample() {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const[metadata,setMetaData]=useState()

  $(document).ready(function () {
    $("#b-card").click(function () {
      $("#b-card").css("border", "1px solid #CD2034");

    });
  });
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    booking_date: '',
    mobile: '',
    passport: '',
    id_card_number: '',
    is_select_bike: ''
  });

  // const loaction = useLocation()

  // if (loaction.pathname === '/gallery') {
  //   $(".navbar").css("z-index", "auto");


  // }
  // else {
  //   $(".navbar").css("z-index", "1020");
  // }






  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmits = (event) => {
    event.preventDefault();
  };

  const [menus, setMenus] = useState()

  useEffect(() => {
    axios.get('https://admin.autorentmotorcycle.com/api/getMenus')
      .then(response => { setMenus(response.data.data); setIsLoading(false) });
  }, [])

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform login logic here
    setUsername('');
    setPassword('');

  };


  $(document).ready(function () {
    $("#myButton").click(function () {
      $("#myElement").css("display", "none");
      $("#myElement1").css("display", "block");
    });
  });




  $(document).ready(function () {
    $("#myButton1").click(function () {
      $("#myElement1").css("display", "none");
      $("#myElement").css("display", "block");
    });
  });

  const options = {

    // autoplay: true, // Enable auto play
    // autoplayTimeout: 3000, // Set auto play interval (in milliseconds)


    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,

      }
    }
  }






  return (
    <>
      {isLoading ? <Container>
        <Row style={{ height: '650px', padding: '0px' }}>
          <Col style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '10%' }}>
            {/* <Spinner style={{color:'red'}}/>  */}
            <img src='/Asest/Homepage/giphy.gif' width={'100px'} height={'100px'} />
          </Col>
        </Row>
      </Container>:

        <Navbar expand="lg" sticky='top' className='navbar'>
          <Nav className='me-auto-3'>
            <Container fluid>
              <Row>
                <Col xs={6} className='text-center'>
                  <Nav.Link data-toggle="modal" data-target="#exampleModal-1" className='book-now-btn-navbar'  ><p style={{ color: 'white' }}>Enquiry </p></Nav.Link>
                </Col>
                {/* <Nav.Link><BsFillTelephoneForwardFill />&nbsp;&nbsp;&nbsp;
   600 549 993</Nav.Link>
   <Nav.Link>|&nbsp;
   Login
   </Nav.Link> */}
                <Col xs={6} className='text-center icon-xs-col'>
                  <div style={{ display: 'flex' }}>
                    <Nav.Link> <span> <a href='https://www.facebook.com/profile.php?id=100088953366323'><FaFacebookF color='white' ></FaFacebookF></a></span></Nav.Link>
                    <Nav.Link><span><a href='https://www.instagram.com/autorentmotorcyclerental/'><FiInstagram color='white' /></a> </span></Nav.Link>
                    <Nav.Link><span><a href='https://www.linkedin.com/company/91067150/admin/'><FaLinkedinIn color='white'></FaLinkedinIn></a> </span></Nav.Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Nav>
          <Navbar.Brand href='/'>
            <img src='/Brands/thumbnail_Autorent_Motorcycle_Logo-01.png' width={'140px'} height={'auto'} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav"  >

            <Nav className="me-auto">

              <Nav.Link><Link to={'/'}><FaHome /></Link></Nav.Link>
              {menus && menus.map((item, index) => {
                return (
                  <Nav.Link key={index}><Link to={item.slug}   >
                    {item.menu_name}
                  </Link></Nav.Link>
                )
              })}


            </Nav>

          </Navbar.Collapse>
          <Nav className='me-auto-2' style={{marginLeft:"3%"}}>
            <Nav.Link data-toggle="modal" data-target="#exampleModal-1" className='book-now-btn-navbar' ><p style={{ fontWeight: '500' }}>Enquiry </p>
            </Nav.Link>
            <Nav.Link style={{ fontWeight: 'bold' }}><BsFillTelephoneForwardFill />&nbsp;&nbsp;&nbsp;
              600 549 993
            </Nav.Link>
            <Nav.Link>|&nbsp;
              Login
            </Nav.Link>
            <Nav.Link href='https://www.facebook.com/profile.php?id=100088953366323' target='_blank'><FaFacebookF ></FaFacebookF></Nav.Link>
            <Nav.Link href='https://www.instagram.com/autorentmotorcyclerental/' target='_blank'><FiInstagram /></Nav.Link>
            <Nav.Link href='https://www.linkedin.com/company/91067150/admin/' target='_blank'><FaLinkedinIn></FaLinkedinIn></Nav.Link>
            <Nav.Link href='' target='_blank'><FaWhatsapp></FaWhatsapp></Nav.Link>
          </Nav>
        </Navbar>

      }
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header" style={{ display: 'flex', justifyContent: 'end' }}>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ background: '#CD2034', color: 'white', border: 'none' }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div id="myElement1" style={{ display: 'none' }}>
                <h5 className='text-center'>Regiter Page</h5>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      value={username}
                      onChange={handleUsernameChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </div>
                  <Button id="myButton1">Already have an account</Button>

                  <div className='text-center'>
                    <button type="submit" className="btn btn-primary mt-4" style={{ width: '50%' }}>Regiter</button>
                  </div>
                </form>
              </div>
              <div className="container " id='myElement'>
                <h5 className='text-center'>Login Page</h5>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      value={username}
                      onChange={handleUsernameChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </div>



                  <Button id="myButton"> You don't have an account sing up </Button>

                  <Button style={{ marginLeft: '7.5%' }}>forgot password</Button>
                  <div className='text-center'>
                    <button type="submit" className="btn btn-primary mt-4" style={{ width: '50%' }}>Login</button>
                  </div>
                </form>
              </div>

            </div>

          </div>
        </div>
      </div>












      {/* booking detils start */}



      <BookNow />




      {/* Booking details end */}


    </>
  )
}








export default ColorSchemesExample;