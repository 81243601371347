import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { BsDot, BsEyeFill } from 'react-icons/bs'
import { BiBullseye } from 'react-icons/bi'
import { FaHands } from 'react-icons/fa'
import Footer from './Footer';
import { Link, json } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

function About() {


    const [about, setAbout] = useState();
    const [metatage, setMetaData] = useState()
    const [isLoading, setIsLoadings] = useState(true)


    useEffect(() => {
        axios.get('https://admin.autorentmotorcycle.com/api/getAboutUs')
            .then(res => { setAbout(res.data.data); setIsLoadings(false) })
            .catch(error => console.error('Error fetching data:', error));

        axios.get('https://admin.autorentmotorcycle.com/api/getSeoMetaTagsBySlug/about-us')
            .then(res => { setMetaData(res.data.data) })
    }, [])


    return (
        <>
            {metatage &&
                <Helmet>
                    <title>{metatage.seo.meta_title}</title>

                    <meta name="description" content={metatage.seo.meta_description} />
                    <meta name="keywords" content={metatage.seo.meta_keywords} />
                    <link rel="canonical" href={metatage.seo.canonical} />
                    <meta name="robots" content={metatage.seo.robots} />
                    <meta property="og:title" content={metatage.seo.og_title} />
                    <meta property="og:description" content={metatage.seo.og_description} />
                    <meta property="og:image" content={metatage.seo.og_image} />
                    <meta property="og:url" content={metatage.seo.og_url} />
                    <meta property="og:type" content={metatage.seo.og_type} />
                    <meta property="og:locale" content={metatage.seo.og_locale} />
                </Helmet>
            }

            {isLoading ? <Container>
                <Row style={{ height: '650px', padding: '0px' }}>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '10%' }}>
                        {/* <Spinner style={{color:'red'}}/>  */}
                        <img src='/Asest/Homepage/giphy.gif' width={'100px'} height={'100px'} />
                    </Col>
                </Row>
            </Container> : about &&
            <Container fluid className='mt-5'>

                <h4 className='about-heading'>About Us</h4>
                <Row className='row-about mt-5'>
                    <Col lg={3}>
                        <Card >
                            <div style={{ minHeight: '60px', display: 'grid', justifyContent: 'center' }}>
                                <span className='text-center mt-5 '><BsEyeFill /></span>
                                <h5 className='mb-4'>{about.vision_title}</h5>
                            </div>
                            <div style={{ minHeight: '150px' }}>

                                <p className='text-center'> {parse(about.vision_description)}</p>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={3}>
                        <Card>
                            <div style={{ minHeight: '60px', display: 'grid', justifyContent: 'center' }}>
                                <span className='text-center mt-5'><BiBullseye /></span>

                                <h5 className='mb-4'>{about.mission_title}</h5>
                            </div>
                            <div style={{ minHeight: '150px' }}>
                                <p className='text-center'>{parse(about.mission_description)}</p>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={3} >
                        <Card className='value-card'>
                            <div style={{ minHeight: '60px', display: 'grid', justifyContent: 'center' }}>
                                <span className='mt-5 '><FaHands /></span>
                                <h5 className='mb-4'>{about.value_title}</h5>
                            </div>
                            <div style={{ minHeight: '150px' }}>
                                <p><span> Integrity</span></p>
                                <p><span> Ownership</span></p>

                                <p><span> Passion</span></p>

                                <p><span>Excellence</span></p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <h4 className='About-Autorent mt-5'>About Autorent</h4>
                <Row className='mt-5 about-row-2'>
                    <h5 className='text-center' style={{ marginTop: '-3%', fontSize: '24px' }}>What makes us different?</h5>
                    <Col lg={5} className='mt-4 '>
                        <h5 className='mb-4'>We have diversified in transportation</h5>
                        {/* <h5 className='mb-4'> We have diversified into different segment</h5> */}
                        <p style={{ position: 'relative', right: '1.8%', fontWeight: '650' }}><BsDot fontSize={'30px'} color='black'></BsDot> <a href='https://autorent-me.com' className='external-link' target='_blank'> Autorent Car Rental </a></p>
                        <p style={{ position: 'relative', right: '1.8%' }}><BsDot fontSize={'30px'}></BsDot>Autorent Truck Rental</p>
                        <p style={{ position: 'relative', right: '1.8%' }}><BsDot fontSize={'30px'}> </BsDot>Autorent Bus Rental</p>
                        <p style={{ position: 'relative', right: '1.8%' }}><BsDot fontSize={'30px'}></BsDot>Chiller Trucks</p>
                        <p style={{ position: 'relative', right: '1.8%' }}><BsDot fontSize={'30px'}></BsDot>Freezer Trucks</p>
                        <p style={{ position: 'relative', right: '1.8%' }}><BsDot fontSize={'30px'}></BsDot>Chaueffeur Services</p>
                    </Col>
                    <Col lg={5} className='mt-4'>

                        <h5 className='mb-4' >Part of one of the largest diversified groups<br></br>Bahwan International Group</h5>
                        <ul style={{ position: 'relative', right: '12px' }}>
                            <li> <p> We are present in Iraq, KSA, Qatar, UAE and Oman</p></li>
                            <li> <p> Motorcycle brands we cater are Honda Unicorn, Bajaj Pulsar,Hero Hunk and TVS Apache</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row style={{ background: '#00509F', padding: '30px', display: 'flex', justifyContent: 'center' }} className='mt-5 mb-5 '>
                    <Col lg={10}>
                        <Row style={{ display: 'flex', justifyContent: 'center' }} >
                            <Col lg={7} className='contact-page-link'>
                                <h3 style={{ color: 'white' }} className='text-end'>CONTACT US TODAY TO KNOW MORE </h3>

                            </Col>
                            <Col className='ab-btn-col' lg={3}>
                                <Link to='/contact'>   <Button className='ab-btn'>
                                    Get In Touch
                                </Button></Link>
                            </Col>
                        </Row></Col>
                </Row>


            </Container>
            }
            <Footer />

        </>
    )
}

export default About
