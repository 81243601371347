import { Button } from 'bootstrap-4-react/lib/components'
import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { BsFillTelephoneFill, BsChevronRight, BsTelephone } from 'react-icons/bs'
import { ImLocation2 } from 'react-icons/im'
import { MdEmail } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { BsArrowRightShort } from 'react-icons/bs'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'

function Footer() {
    const [number, setNumber] = useState()


    const submitdata = (e) => {
        e.preventDefault()
        axios.post('https://admin.autorentmotorcycle.com/api/storeRequestCallback', { phone_number: number })
            .then(res => {
                setNumber('')
                toast(res.data.message)
            })
            .catch((error) => {
                toast(error.response.data.data.phone_number[0])
            })


    }


    return (

        <>
            <ToastContainer />

            <Container fluid style={{
                backgroundImage:
                    'url("/Asest/Homepage/bg1.jpg")'
            }} className='footer'>
                <Row className='footer-img-col' >
                    <Col lg={12} md={12}>
                        {/* <div className='footer-image'>
        <img  src='/Asest/Homepage/MicrosoftTeams-image (32).png' width={'100px'} style={{marginTop:'10%',marginLeft:'5%'}}/>
        </div> */}
                    </Col>
                </Row>
                <Row style={{ marginTop: '-4%' }}  >
                    <Col lg={4} md={3}>
                        <div className='footer-div-1'>
                            <h6>How can we be at your service?</h6>
                            <h6 style={{ marginTop: '-5px', marginBottom: '-1px' }}>TALK TO US</h6>
                            <h6>Request a callback</h6>

                            <form className='mt-3' onSubmit={submitdata}>
                                <input
                                    type='phone' value={number} onChange={(e) => { setNumber(e.target.value) }} className='footer-btn' placeholder='Enter your telephone number' />
                                <span className='footer-phone-btn'><button type='submit'><BsFillTelephoneFill color='white' /></button></span>
                            </form>
                        </div>
                    </Col>
                    <Col lg={2} md={3} className='q-links'>
                        <div className='quick-links' >
                            <h6>Quick Links</h6>
                            <Link to={'/'}>    <p><BsChevronRight color='white' /> Home</p></Link>
                            <Link to={'/about-us'}><p> <BsChevronRight color='white' /> About us</p></Link>
                            <Link to={'/gallery'}> <p><BsChevronRight color='white' /> Gallery</p></Link>
                            <Link to={'/brand'}>   <p><BsChevronRight color='white' /> Brands</p></Link>
                            <Link to={'/contact'}> <p><BsChevronRight color='white' /> Contact</p></Link>
                        </div>
                    </Col>
                    <Col lg={2} md={3} className='q-links'>
                        <div className='quick-links' >
                            <h6>Important Links</h6>
                            <Link to={'/TermsAndConditiondsData'}> <p><BsChevronRight color='white' />Terms & Condtions</p></Link>
                            <Link to={'/PrivacyPolicyData'}> <p> <BsChevronRight color='white' />Privacy Policy</p></Link>

                        </div>
                    </Col>
                    <Col lg={4} md={5} >
                        <div className='quick-links'>
                            <h6>Contact us</h6>
                            <Row>
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <ImLocation2 color='white' />
                                </Col>
                                <Col lg={11} md={11} xs={11}>
                                    <p> Ind. 2nd - Plot No. 776  ,<br></br>  Building No. 1 Nad Al Sheba - 89 Manama ,<br></br>St - Ras Al Khor - Dubai - United Arab Emirates .</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <BsFillTelephoneFill color='white' />
                                </Col>
                                <Col lg={11} md={11} xs={11}>
                                    <p className='mt-1' >+971 600 549993</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <MdEmail color='white' />
                                </Col>
                                <Col lg={11} md={11} xs={11} className='footer-bottom'>
                                    <p className='mt-1'>info@autorentmotorcycle.com</p>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default Footer
